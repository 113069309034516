import React, { useState } from 'react';
import LandingPage from './LandingPage';
import './App.css';
import About from './About';
import Projects from './Projects';
import Contact from './Contact';
import overlayImage from './images/waves.gif'; // Import the overlay image
import fantasylogo from './images/StudioFantasylogo.png'; 
import fantasylogoGLOW from './images/StudioFantasylogoGLOW.png'; 

function App(): JSX.Element {
  const [activeTab, setActiveTab] = useState<string>('home');

  const handleTabClick = (tabName: string): void => {
    setActiveTab(tabName);
  };

  const renderTabContent = (): JSX.Element => {
    switch (activeTab) {
      case 'about':
        return <About />;
        case 'home':
        return  <LandingPage imageUrl="https://via.placeholder.com/1200x800" />
        case 'projects':
          return <Projects />;
        case 'contact':
          return <Contact />;
        default:
        return <LandingPage imageUrl="https://via.placeholder.com/1200x800" />
    }
  };

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };

  return (
    <div className="app-page">
      <div className='backdrop'></div>
      <img className="overlay-image" src={overlayImage} alt="Overlay" /> {/* Add the overlay image element */}
      <img className="overlay-image2" src={overlayImage} alt="Overlay" /> {/* Add the overlay image element */}
      <nav className="header">
      <a href="/">
      {/*<h1>Studio Fantasy</h1> */}
      {isHovered ? (
        <img
          className='logo-image'
          src={fantasylogoGLOW}
          alt="Studio Fantasy"
          onMouseOut={handleMouseOut}
        />
      ) : (
        <img
          className='logo-image'
          src={fantasylogo}
          alt="Studio Fantasy"
          onMouseOver={handleMouseOver}
        />
      )}
      {/* <img className="logo-image" src={fantasylogo} alt="Studio Fantasy"/> */}
      </a>
        <ul>
          <li className={activeTab === 'home' ? 'active' : ''}>
            <button onClick={() => handleTabClick('home')}>Home</button>
          </li>
          <li className={activeTab === 'about' ? 'active' : ''}>
            <button onClick={() => handleTabClick('about')}>About</button>
          </li>
          <li className={activeTab === 'projects' ? 'active' : ''}>
            <button onClick={() => handleTabClick('projects')}>Projects</button>
          </li>
          <li className={activeTab === 'contact' ? 'active' : ''}>
            <button onClick={() => handleTabClick('contact')}>Contact</button>
          </li>
        </ul>
      </nav>

      <div className="tab-content">{renderTabContent()}</div>
    </div>
  );
}

export default App;