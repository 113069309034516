import React from 'react';
import './App.css';

const About: React.FC = () => {
  return (
    <div className='main-content'>
      <h1>About Us</h1>
      <p> We passionately believe in the power of creativity to transform lives and transcend boundaries. Our mission is to nurture, empower, and celebrate artists and creators from every corner of the world, fostering an inclusive, collaborative, and innovative community that drives positive change in the art industry.</p>
    <p>We are dedicated to providing unparalleled opportunities for our diverse network of talent to develop and showcase their unique voices, while offering tailored support to help them build thriving, sustainable careers in the art world. By leveraging our global connections and expertise, we champion cultural exchange, inspire creative growth, and promote the value of art in enriching the human experience.
</p>
<p>Together, we strive to create a vibrant and accessible global art ecosystem that empowers artists, elevates diverse perspectives, and inspires a new generation of creators to shape a more vibrant, connected, and culturally rich world.
</p>

    </div>
  );
}

export default About;