import { NONAME } from 'dns';
import React, { useState } from 'react';
import Home from './LandingPage';
import './LandingPage.css';


type LandingPageProps = {
  imageUrl: string;
};

function LandingPage(props: LandingPageProps): JSX.Element {
  return (
    <div className="landing-page">
      <div className="main-content">

      

        <h2>Welcome to Studio Fantasy</h2>
        <p>Unleashing global creativity, fostering artistic careers, and celebrating diversity</p>
        
        
        <form action="https://www.paypal.com/donate" method="post" target="_top">
<input type="hidden" name="business" value="6BHSUTET9VMCC" />
<input type="hidden" name="no_recurring" value="0" />
<input type="hidden" name="item_name" value="Help us at Fantasy Studios get started in creating a vibrant and accessible art ecosystem that empowers artists and creators!" />
<input type="hidden" name="currency_code" value="USD" />
<input type="button" style={{opacity: 0}} name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
<button className="cta-button">Donate to help us get started</button>
</form>


        
      </div>
    </div>
  );
}

export default LandingPage;