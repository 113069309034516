import React from 'react';
import './App.css';
import morningstarlogo from './images/morningstarglow.png'; // Import the overlay image

const About: React.FC = () => {
  return (
    <div className='main-content'>
      <img className="centered-image" src={morningstarlogo} alt="Overlay" style={{width: 400}}/> {/* Add the overlay image element */}
      
      <p>We are thrilled to introduce you to our upcoming IP, Morning Star. This exciting project brings together artists of all backgrounds, from beginner to professional, to create a truly unique experience that sets the foundation for our vision of the future. </p>
    <p>At launch, Morning Star will be a Mobile TCG Tower Defense, providing the financial foundation we need to pursue even more ambitious projects in the future. With a focus on accessibility and engagement, we believe that Morning Star has the potential to capture the hearts and minds of gamers everywhere.</p>
    <p>We are devoted to our vision for Morning Star and we believe that it has the potential to become an industry leader. We invite you to join us on this exciting journey and help us bring Morning Star to life.</p>
    
    </div>
  );
}

export default About;