import React from 'react';
import './App.css';

const About: React.FC = () => {
  return (
    <div className='main-content'>
      <h1>Contact Us</h1>
      <p>Looking to join our team or support our project? We are always on the lookout for artists, developers, and investors who share our vision for creating innovative and engaging projects in the world of entertainment. Whether you're an experienced professional or just starting out, we'd love to hear from you. If you're interested in learning more about our company, our projects, or how you can get involved, please don't hesitate to reach out to us at <span style = {{fontWeight:900}}>brady.osborne@fantasy-studios.com</span> . We look forward to hearing from you! </p>


    </div>
  );
}

export default About;